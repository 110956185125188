import {LOCALE} from "../config";
import {TRANSLATIONS} from "../constants/translations";

export default function translate(englishString: string) {
  return (
    (TRANSLATIONS &&
      TRANSLATIONS[englishString] &&
      TRANSLATIONS[englishString][LOCALE]) ||
    englishString
  );
}
