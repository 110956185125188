import React, {ReactNode} from "react";
import {datadogRum} from "@datadog/browser-rum-slim";
import {Script} from "gatsby";

// global style, it's very IMPORTANT that this should load first
// before loading any other component
import "./src/styles/common.scss";

import {initSentry, logErrorToSentry} from "./src/utils/sentry";
import {updateUtmCookies} from "./src/utils/UTM-tracking";
import {logEvent} from "./src/utils/amplitude";
import ErrorBoundary from "./src/components/error-boundary/ErrorBoundary";
import GDPRWrapper from "./src/components/gdpr/wrapper/GDPRWrapper";
import FlexportAsciiShip from "./src/constants/flexport-ascii-ship";
import {logPath} from "./src/utils/path-logging";
import WindowSizeProvider from "./src/contexts/window-context";
import {AlgoliaContext} from "./src/contexts/context";
import {checkMsclkid} from "./src/utils/microsoft-click-id";

// inert polyfill
import "wicg-inert";

import {
  GATSBY_DATADOG_CLIENT_TOKEN,
  GATSBY_DATADOG_APPLICATION_ID,
  NODE_ENV,
  GATSBY_RECAPTCHA_SITE_KEY,
} from "./src/config";

export const wrapRootElement = ({element}: {element: ReactNode}) => {
  return (
    <ErrorBoundary>
      <GDPRWrapper>
        <WindowSizeProvider>{element}</WindowSizeProvider>
      </GDPRWrapper>
    </ErrorBoundary>
  );
};

export const wrapPageElement = ({
  element,
  props,
}: {
  element: ReactNode;
  props: any;
}) => {
  return (
    <>
      <Script
        id="fp-grecaptcha"
        src={`https://www.recaptcha.net/recaptcha/enterprise.js?render=${GATSBY_RECAPTCHA_SITE_KEY}`}
        strategy="idle"
      />
      {/*
        passing along algolia context here
        grab it from the page context
        this way, we don't need to create new env prefixed with GATSBY_
      */}
      <AlgoliaContext.Provider value={props.pageContext.algoliaIndex!}>
        {element}
      </AlgoliaContext.Provider>
    </>
  );
};

export const onRouteUpdate = ({location}: {location: Location}) => {
  logEvent("gatsbyRouteUpdate", {
    path: location.pathname,
  });

  logPath(location.pathname);

  // check and store microsoft click id
  checkMsclkid();
};

export const onInitialClientRender = () => {
  if (NODE_ENV === "production") {
    datadogRum.init({
      applicationId: GATSBY_DATADOG_APPLICATION_ID!,
      clientToken: GATSBY_DATADOG_CLIENT_TOKEN!,
      site: "datadoghq.com",
      service: "flexport-static-site",
      env: "production",
      sampleRate: 100,
      trackInteractions: true,
      defaultPrivacyLevel: "mask-user-input",
    });
    datadogRum.startSessionReplayRecording();
  }

  initSentry();

  try {
    /*
      All scripts should be put inside this try-catch block, so we can track all
      errors in the sentry
    */

    updateUtmCookies();

    if (process.env.NODE_ENV === "production") {
      /* eslint-disable-next-line no-console */
      console.log(FlexportAsciiShip);
    }
  } catch (error) {
    logErrorToSentry(error);
  }
};

// export const replaceHydrateFunction = () => {
//   return (element, container) => {
//     const root = ReactDOM.createRoot(container);
//     root.render(element);
//   };
// };
