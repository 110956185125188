/* eslint-disable quotes */

const FlexportAsciiShip = [
  "  We're hiring engineers! http://www.flexport.com/careers/teams/engineering",
  "      ________                           __",
  "     / ____/ /__  _  ______  ____  _____/ /_",
  "    / /_  / / _  | |/_/ __ \\/ __ \\/ ___/ __/",
  "   / __/ / /  __/>  </ /_/ / /_/ / /  / /_",
  "  /_/   /______/_/|_/  ___/_____/_/   __/        _",
  "        / ____/ _     __ _(_)___  ___  ___  _____(_)___  ____",
  "       / __/ / __ \\/ __ `/ / __ \\/ _ \\/ _ \\/ ___/ / __ \\/ __ `/",
  "      / /___/ / / / /_/ / / / / /  __/  __/ /  / / / / / /_/ /",
  "     /_____/_/ /_/\\__, /_/_/ /_/\\___/\\___/_/  /_/_/ /_/\\__, /",
  "                 /____/                               /____/",
  "       | -",
  "     _========_",
  "     |        |[__]    [__][__][__][__][__][__][__][__][__][__][__][__][__][__]     [__]",
  "     |        |[__][__][__][__][__][__][__][__][__][__][__][__][__][__][__][__][__] [__][__]",
  "     |        |[__][__][__][__][__][__][__][__][__][__][__][__][__][__][__][__][__] /=====================",
  "  =================================================================================/                     /",
  `   \\""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""/`,
  "    \\__________________________________________________________________________________________________/",
  "  ...\\ .... ... . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . /",
  "  ^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^",
].join("\n");

export default FlexportAsciiShip;
