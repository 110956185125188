const PATH_SESSION_STORAGE = "FLEXPORT_PATH_LOG";
const MAX_PATHS = 5;

/**
 * util method to check if session storage is enabled
 * @return {boolean}
 */
export function sessionStorageEnabled(): boolean {
  if (typeof window === "undefined") {
    return false;
  }

  try {
    const key = "__flexport-storage-test";

    window.sessionStorage.setItem(key, "null");
    window.sessionStorage.removeItem(key);

    return true;
  } catch (e) {
    return false;
  }
}

/**
 * helper method to log visited path into session storage
 * @param {string} path
 * @return {void}
 */
export function logPath(path: string): void {
  if (typeof window === "undefined" && sessionStorage === undefined) {
    return;
  }

  try {
    const item = sessionStorage.getItem(PATH_SESSION_STORAGE);
    const paths = item != null ? JSON.parse(item) : [];
    const newPaths = [...paths, path].splice(MAX_PATHS * -1);

    // don't log a path if it's the same as the last one
    // we don't want page refreshes to "contaminate" the data
    if (paths.pop() === path) {
      return;
    }

    sessionStorage.setItem(PATH_SESSION_STORAGE, JSON.stringify(newPaths));
  } catch (error) {
    console.error("Error in logging path:", error);
  }
}

/**
 * Returns the last two paths, excluding the currently-viewed page, visited.
 * Example: ['a', 'b', 'c'] => ['a', 'b'] since 'c' would be the page the user is on.
 *
 * @param {number} count
 * @return {Array<string>}
 */
export function getLastPaths(count: number = MAX_PATHS): Array<string> {
  if (typeof window === "undefined") {
    return [];
  }

  const item = window.sessionStorage.getItem("FLEXPORT_PATH_LOG");

  // we remove the last item because it would presumably be the currently-viewed page
  return item != null
    ? JSON.parse(item)
        .slice(0, -1)
        .slice(count * -1)
    : [];
}
