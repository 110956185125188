// from https://gist.github.com/henrik/1688572
export const EU_COUNTRIES: {[key: string]: string} = {
  AL: "AL",
  AD: "AD",
  AT: "AT",
  AZ: "AZ",
  BY: "BY",
  BE: "BE",
  BA: "BA",
  BG: "BG",
  HR: "HR",
  CY: "CY",
  CZ: "CZ",
  DK: "DK",
  EE: "EE",
  FI: "FI",
  FR: "FR",
  GE: "GE",
  DE: "DE",
  GR: "GR",
  HU: "HU",
  IS: "IS",
  IE: "IE",
  IT: "IT",
  KZ: "KZ",
  XK: "XK",
  LV: "LV",
  LI: "LI",
  LT: "LT",
  LU: "LU",
  MK: "MK",
  MT: "MT",
  MD: "MD",
  MC: "MC",
  ME: "ME",
  NL: "NL",
  NO: "NO",
  PL: "PL",
  PT: "PT",
  RO: "RO",
  RU: "RU",
  SM: "SM",
  RS: "RS",
  SK: "SK",
  SI: "SI",
  ES: "ES",
  SE: "SE",
  CH: "CH",
  TR: "TR",
  UA: "UA",
  GB: "GB",
  VA: "VA",
};

export function isEUCountryCode(countryCode: string): boolean {
  return !!EU_COUNTRIES[countryCode];
}
