import amplitude from "amplitude-js";
import Cookies from "js-cookie";
import {getUtmCookiesMap} from "./UTM-tracking";

interface AdditionalProperties {
  // eslint-disable-next-line camelcase
  sign_up_experiment?: string;
  email?: string;
  splitTest?: Array<string>;
  isMarketableLead?: string;
}

/* Each amplitude event should register its name and
 * properties type in AmplitudeEvents. This helps us ensure
 * we never forget to add properties required by a dashboard
 * in an amplitude event log call.
 */
/* eslint-disable camelcase */
interface AmplitudeEvents {
  gatsbyRouteUpdate: {
    path: string;
  };
  "click:marketing_site_cta": {
    name: string;
    type: string;
    contentType?: string;
    // deprecated prop. Use type instead
    linkUrl?: string;
  };
  "click:embedded_form_sign_up": void;
  "click:search_icon": {
    action: string;
  };
  "click:run_search": {
    input: string;
  };
  "click:search_content": {
    slug: string;
  };
  "click:navigate_search_page": {
    pageNumber: number;
  };
  "load:sign_up_experiment": {
    type: string;
  };
  "error:search_page": {
    err: string;
  };
  "load:signup_flow_1": void;
  "load:signup_flow_2": void;
  "load:signup_flow_complete": void;
  "load:signup_flow_via_homepage": void;
  "load:index": void;
  "load:signup_page": void;
  "load:marketing_page": {
    page: string;
  };
  "load:partial_form_complete": {
    formTitle: string;
  };
  "load:subscription": {
    formTitle: string;
  };
  "click:rtq_cta": {
    name: string;
    origin?: string;
    mode?: string;
    container_type?: string;
    number_of_results?: number;
    service_level?: string;
    transit_time?: string;
    price?: string;
    linkUrl?: string;
    page?: string;
    url?: string;
    destination?: string;
  };
  "load:homepage_primary": void;
  "load:homepage_experiment": void;
  "click:paps_tracker": {
    name: string;
    page?: string;
    param?: string;
  };
  "load:paps_tracker_results": {
    is_success: string;
    error?: string;
  };
  "click:pars_tracker": {
    name: string;
    page?: string;
    param?: string;
  };
  "load:pars_tracker_results": {
    is_success: string;
    error?: string;
  };
  "load:emissions_calculator": void;
  "click:emissions_calculator_calculate_button": void;
  "load:emissions_calculator_maximum_shipments_calculated": void;
  "load:open_emissions_signup_page": void;
  "click:open_emissions_signup_button": void;
  "load:open_emissions_successful_signup": void;
}
/* eslint-enable camelcase */
export type AmplitudeCtaProps = Pick<
  AmplitudeEvents,
  "click:marketing_site_cta"
>["click:marketing_site_cta"];

type EventType = keyof AmplitudeEvents;

interface PromiseResult {
  responseCode?: number;
  responseBody?: string;
}

let isAmplitudeInitialized: boolean = false;
let userProperties = {};
// For enqueuing all events before amplitude sdk is initialized.
const eventQueue: Array<{
  eventType: EventType;
  properties: AmplitudeEvents[keyof AmplitudeEvents];
}> = [];

export function isInitialized(): boolean {
  return isAmplitudeInitialized;
}

/**
 * init amplitude
 * @param {string} apiKey
 *
 * @return {void}
 */
export function init(apiKey: string): void {
  amplitude.getInstance().init(
    apiKey,
    undefined,
    {
      includeUtm: true,
      includeReferrer: true,
    },
    () => {
      isAmplitudeInitialized = true;
      eventQueue.forEach(({eventType, properties}) => {
        amplitude.getInstance().logEvent(eventType, properties);
        amplitude.getInstance().setUserProperties(userProperties);
      });
    }
  );
}

/**
 * function to opt out from amplitude
 * @param {boolean} shouldOptOutAmplitude
 *
 * @return {void}
 */
export function setOptOut(shouldOptOutAmplitude: boolean): void {
  if (isAmplitudeInitialized) {
    amplitude.getInstance().setOptOut(shouldOptOutAmplitude);
  }
}

/**
 * amplitude log event method
 * Please use this function rather than calling amplitude.
 * logEvent directly. This function is typed to ensure all
 * required amplitude properties are included for each event
 * call.
 * The standard amplitude API accepts a callback as its third
 * argument. We differ here by returning a promise instead.
 * @param {EventTypeKey} eventType
 * @param {any} properties
 * @return {Promise<PromiseResult>}
 */
export function logEvent<EventTypeKey extends EventType>(
  eventType: EventTypeKey,
  properties?: any
): Promise<PromiseResult> {
  if (!isAmplitudeInitialized) {
    eventQueue.push({
      eventType,
      properties,
    });
    return Promise.resolve({});
  }

  return new Promise((resolve, reject) => {
    try {
      amplitude
        .getInstance()
        .logEvent(
          eventType,
          properties,
          (responseCode: number, responseBody: string) => {
            if (responseCode === 200 && responseBody === "success") {
              resolve({
                responseCode,
                responseBody,
              });
              return;
            }

            if (responseCode === 0 && responseBody === "No request sent") {
              // TODO: Log this to datadog. This indicates that a user has
              // opted out of tracking.
              resolve({
                responseCode,
                responseBody,
              });
              return;
            }

            reject(
              new Error(`Attempt to log to amplitude returned ${responseCode}`)
            );
          }
        );
    } catch (err) {
      reject(err);
    }
  }) as Promise<PromiseResult>;
}

/**
 * method for Update user properties to amplitude.
 * Please use this function rather than calling amplitude.
 * setUserProperties directly. This function is typed to
 * ensure all required amplitude properties, including
 * experiments, are included for each event call.
 *
 * @param {AdditionalProperties} additionalProperties
 * @return {void}
 */
export function updateUserProperties(
  additionalProperties: AdditionalProperties
) {
  const isFlexportCustomer = (
    Cookies.get("flexport_domain") === "clients"
  ).toString();

  const newProperties = {
    /* eslint-disable camelcase */
    marketo_tracking_id: Cookies.get("_mkto_trk") || "",
    // We're getting visitor_id directly from cookie instead of using
    // pi.tracker.visitor_id because the later one doesn't work due to
    // an issue on Pardot side.
    pardot_tracking_id: Cookies.get("visitor_id925323") || "",
    is_flexport_customer: isFlexportCustomer,

    /* eslint-enable camelcase */
    ...getUtmCookiesMap(),
    ...additionalProperties,
  };

  userProperties = {...userProperties, ...newProperties};

  if (isAmplitudeInitialized) {
    amplitude.getInstance().setUserProperties(userProperties);
  }
}
