import {init, setTags, setExtras, captureException} from "@sentry/browser";
import {ExtraErrorData} from "@sentry/integrations";
import Cookies from "js-cookie";
import invariant from "./invariant";

import {
  GATSBY_SENTRY_DEV_DSN,
  GATSBY_SENTRY_ENV,
  GATSBY_SENTRY_PROD_DSN,
} from "../config";

invariant(
  GATSBY_SENTRY_ENV != null,
  "GATSBY_SENTRY_ENV must be defined in .env"
);

invariant(
  GATSBY_SENTRY_DEV_DSN != null,
  "GATSBY_SENTRY_DEV_DSN must be defined in .env"
);

invariant(
  GATSBY_SENTRY_PROD_DSN != null,
  "GATSBY_SENTRY_PROD_DSN must be defined in .env"
);

export interface SentryType {
  tags?: Record<string, string>;
  extras?: Record<string, string>;
}

/**
 * init sentry client
 */
export const initSentry = (): void => {
  init({
    dsn: GATSBY_SENTRY_ENV === "production" ? GATSBY_SENTRY_PROD_DSN : "",
    environment: GATSBY_SENTRY_ENV,
    integrations: [new ExtraErrorData()],
    ignoreErrors: [
      /.*\(analytics\)$/i, // marketing analytics scripts
      /Loading\schunk\s\d+\sfailed.*/i, // chunk loading errors (slow internet)
      // Random plugins/extensions
      "top.GLOBALS",
      // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
      "originalCreateNotification",
      "canvas.contentDocument",
      "MyApp_RemoveAllHighlights",
      "http://tt.epicplay.com",
      "Can't find variable: ZiteReader",
      "jigsaw is not defined",
      "ComboSearch is not defined",
      "http://loading.retry.widdit.com/",
      "atomicFindClose",
      // Facebook borked
      "fb_xd_fragment",
      // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
      // reduce this. (thanks @acdha)
      // See http://stackoverflow.com/questions/4113268
      "bmi_SafeAddOnload",
      "EBCallBackMessageReceived",
      // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
      "conduitPage",
      // See https://forum.sentry.io/t/unhandledrejection-non-error-promise-rejection-captured-with-value/14062/16
      "Non-Error promise rejection captured",
      "window.lintrk is not a function",
    ],
    denyUrls: [
      // Facebook flakiness
      /graph\.facebook\.com/i,
      // Facebook blocked
      /connect\.facebook\.net\/en_US\/all\.js/i,
      // Woopra flakiness
      /eatdifferent\.com\.woopra-ns\.com/i,
      /static\.woopra\.com\/js\/woopra\.js/i,
      // Chrome extensions
      /extensions\//i,
      /^chrome:\/\//i,
      // Other plugins
      /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
      /webappstoolbarba\.texthelp\.com\//i,
      /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
    ],
  });
};

/**
 * helper method to log error to sentry
 * @param {Error} error
 * @param {SentryType | null | undefined} context
 *
 * @return {void}
 */
export const logErrorToSentry = (
  error: Error,
  context?: SentryType | null | undefined
): void => {
  const {tags = {}, extras = {}} = context || {};
  const isFlexportCustomer: string = (
    Cookies.get("flexport_domain") === "clients"
  ).toString();

  setTags({
    ...tags,
    appName: "flexport-static-site",
    /* eslint-disable camelcase */
    affected_team: "growth",
    is_flexport_customer: isFlexportCustomer,
    /* eslint-enable camelcase */
  });
  setExtras({...extras});
  captureException(error);
};
