import {sessionStorageEnabled} from "./path-logging";
import {getQueryStringParams} from "./utils";

const STORAGE_ITEM_NAME = "FLEXPORT_MSCLKID";
const URL_PARAM_NAME = "msclkid";

let msclkid: string | null = null;

/**
 * grab microsoft click id from query string and store intro local storage
 * @return {void}
 */
export function checkMsclkid(): void {
  if (sessionStorageEnabled() === false) {
    return;
  }

  const urlParams = getQueryStringParams();
  const clickParam: string | null = urlParams.get(URL_PARAM_NAME);

  if (clickParam !== null) {
    window.localStorage.setItem(STORAGE_ITEM_NAME, clickParam);
    msclkid = clickParam;
  } else {
    msclkid = window.localStorage.getItem(STORAGE_ITEM_NAME);
  }
}

/**
 * method to get current microsoft click id
 * @return {string}
 */
export function getMsclkid(): string {
  return msclkid || "";
}
