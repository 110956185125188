import Cookies from "js-cookie";
import {IS_DEV_ENV} from "../config";
import {getQueryStringParams} from "./utils";

/* Deprecated(tchen): This should map the cookies name that we use in SignInApp */
const FP_COOKIE_NAME = "fp-utm";

/*  Deprecated(tchen): Days for flexport cookie expiration. 182.5 matches Google Analytics'
 * behavior of storing UTM values for six months.
 */
const FP_COOKIE_EXPIRATION_AGE = 182.5;

/* eslint-disable camelcase */
interface UTMMap {
  campaign_id?: string;
  gclid?: string;
  GSC_Modal?: string;
  referrer?: string;
  utm_adgroup?: string;
  utm_asset?: string;
  utm_campaign?: string;
  utm_content?: string;
  utm_device?: string;
  utm_matchtype?: string;
  utm_medium?: string;
  utm_network?: string;
  utm_source?: string;
  utm_term?: string;
}
/* eslint-enable camelcase */

const UTM_NAMES = [
  "campaign_id",
  "gclid",
  "GSC_Modal",
  "referrer",
  "utm_adgroup",
  "utm_asset",
  "utm_campaign",
  "utm_content",
  "utm_device",
  "utm_matchtype",
  "utm_medium",
  "utm_network",
  "utm_source",
  "utm_term",
];

/**
 * helper method to store utm tracking value into cookies
 * @return {void}
 */
export function updateUtmCookies() {
  const utmMap: UTMMap = parseUrlUtmParams();

  // If there are no utm params, don't set cookies with empty values, as this can potentially override existing utm cookie values
  const utmMapValues = Object.values(utmMap);
  if (utmMapValues.every(val => val === "")) {
    return;
  }

  Cookies.set(FP_COOKIE_NAME, JSON.stringify(utmMap), {
    expires: FP_COOKIE_EXPIRATION_AGE,
    domain: IS_DEV_ENV ? "localhost" : "flexport.com",
  });

  Object.keys(utmMap).forEach(utm => {
    /* Session cookies. Used for Marketo form hidden fields */
    Cookies.set(utm, utmMap[utm as keyof UTMMap]!);
  });
}

/**
 * helper method to get utm tracking value from cookies
 * as object
 * @return {UTMMap}
 */
export function getUtmCookiesMap(): UTMMap {
  const utmMap: UTMMap = UTM_NAMES.reduce((acc: UTMMap, utm) => {
    acc[utm as keyof UTMMap] = Cookies.get(utm) || "";
    return acc;
  }, {});

  return utmMap;
}

/**
 * generic method to translate query string value
 * to object key value
 * @return {UTMMap}
 */
export function parseUrlUtmParams(): UTMMap {
  const queryParams = getQueryStringParams();
  const utmMap: UTMMap = UTM_NAMES.reduce((acc: UTMMap, utmName) => {
    const paramValue = queryParams.get(utmName) || "";
    acc[utmName as keyof UTMMap] = paramValue;
    return acc;
  }, {});

  if (utmMap.referrer === "") {
    const {referrer} = document;
    utmMap.referrer = referrer;
  }

  return utmMap;
}
