interface Translation {
  [key: string]: {
    [key: string]: string;
  };
}

export const TRANSLATIONS: Translation = {
  "Filter by industry": {
    "de-DE": "Nach Branche filtern",
  },
  Apply: {
    "de-DE": "Anwenden",
  },
  "All Industries": {
    "de-DE": "Alle Branchen",
  },
  "Hello!": {
    "zh-CN": "你好!",
    "de-DE": "Hallo!",
  },
  "Sign In": {
    "zh-CN": "登录",
    "de-DE": "Anmeldung",
  },
  "Get Started": {
    "zh-CN": "开启您的Flexport旅程",
    "de-DE": "Jetzt anmelden",
  },
  "Learn More": {
    "zh-CN": "了解更多",
    "de-DE": "Mehr erfahren",
  },
  "Load More": {
    "zh-CN": "访问更多",
    "de-DE": "Weitere laden",
  },
  "Why Flexport": {
    "zh-CN": "为何选择 Flexport",
    "de-DE": "Warum Flexport",
  },
  "Our Vision": {
    "zh-CN": "我们的愿景",
    "de-DE": "Unsere Vision",
  },
  "Our Tech Platform": {
    "zh-CN": "我们的科技平台",
    "de-DE": "Unsere Tech Plattform",
  },
  "Our Service Model": {
    "zh-CN": "我们的服务模式",
    "de-DE": "Unser Service Modell",
  },
  "Logistics Services": {
    "zh-CN": "物流服务",
    "de-DE": "Logistik Services",
  },
  "Ocean Shipping": {
    "zh-CN": "海运",
    "de-DE": "Seefracht",
  },
  "FCL Shipping": {
    "zh-CN": "整柜海运",
    "de-DE": "FCL-Seefracht",
  },
  "LCL Shipping": {
    "zh-CN": "散货海运",
    "de-DE": "LCL-Seefracht",
  },
  "Air Freight": {
    "zh-CN": "空运",
    "de-DE": "Luftfracht",
  },
  "Ground Transportation": {
    "zh-CN": "陆运",
    "de-DE": "Landverkehr",
  },
  "Supply Chain Services": {
    "zh-CN": "供应链服务",
    "de-DE": "Supply Chain Services",
  },
  "Customs Brokerage": {
    "zh-CN": "清关咨询服务",
    "de-DE": "Zollberatung",
  },
  "Trade Finance": {
    "zh-CN": "贸易金融",
    "de-DE": "Handelsfinanzierung",
  },
  "Carbon Offset": {
    "zh-CN": "碳抵消",
    "de-DE": "CO2-Kompensation",
  },
  "Duty Drawback": {
    "zh-CN": "退税服务",
    "de-DE": "",
  },
  "Order Management": {
    "zh-CN": "订单管理",
    "de-DE": "",
  },
  Resources: {
    "zh-CN": "资源",
    "de-DE": "Ressourcen",
  },
  Blog: {
    "zh-CN": "博客",
    "de-DE": "Blog",
  },
  "Market Updates": {
    "zh-CN": "市场行情动态",
    "de-DE": "Marktupdates",
  },
  "Videos and Webinars": {
    "zh-CN": "视频和在线分享会",
    "de-DE": "Videos & Webinare",
  },
  "Help Center": {
    "zh-CN": "帮助中心",
    "de-DE": "Hilfe-Center",
  },
  Company: {
    "zh-CN": "公司介绍",
    "de-DE": "Unternehmen",
  },
  "Our Story": {
    "zh-CN": "我们的故事",
    "de-DE": "Unsere Story",
  },
  Press: {
    "zh-CN": "媒体资源",
    "de-DE": "Presse",
  },
  Careers: {
    "zh-CN": "加入我们",
    "de-DE": "Karriere",
  },
  Engineering: {
    "zh-CN": "软件开发",
    "de-DE": "Software Entwicklung",
  },
  Contact: {
    "zh-CN": "联系我们",
    "de-DE": "Kontakt",
  },
  Services: {
    "zh-CN": "服务",
    "de-DE": "Services",
  },
  "Latest & Greatest": {
    "zh-CN": "最新资讯",
    "de-DE": "",
  },
  "New Dashboard Updates": {
    "zh-CN": "",
    "de-DE": "Neue Dashboard-Updates",
  },
  Customers: {
    "zh-CN": "客户",
    "de-DE": "Kunden",
  },
  Leadership: {
    "zh-CN": "领导团队",
    "de-DE": "Unternehmensleitung",
  },
  "Read More": {
    "zh-CN": "阅读更多内容",
    "de-DE": "Mehr erfahren",
  },
  "Ready to Get Started?": {
    "zh-CN": "准备好开始了吗？",
    "de-DE": "Sind Sie bereit, mit Flexport zu starten?",
  },
  "Sign up for a Flexport account or ask to see our platform in action.": {
    "zh-CN": "注册 Flexport 账号或要求查看我们的行动平台。",
    "de-DE": "Registrieren Sie sich für Neuigkeiten des Frachtmarktes",
  },
  "Watch Now": {
    "zh-CN": "",
    "de-DE": "Jetzt anschauen",
  },
  "Videos & Webinars": {
    "zh-CN": "",
    "de-DE": "Video und Webinare",
  },
  Webinars: {
    "zh-CN": "线上讲座",
    "de-DE": "Webinare",
  },
  Webinar: {
    "zh-CN": "线上讲座",
    "de-DE": "Webinare",
  },
  Video: {
    "zh-CN": "视频",
    "de-DE": "Video",
  },
  FAQs: {
    "zh-CN": "",
    "de-DE": "",
  },
  "Share the Article": {
    "zh-CN": "",
    "de-DE": "",
  },
  "More From Flexport": {
    "zh-CN": "更多",
    "de-DE": "",
  },
  "White Papers": {
    "zh-CN": "白皮书",
    "de-DE": "",
  },
  "E-Guides": {
    "zh-CN": "电子书",
    "de-DE": "",
  },
  Offices: {
    "zh-CN": "",
    "de-DE": "Unsere Flexport Standorte",
  },
  "Explore Our Teams": {
    "zh-CN": "",
    "de-DE": "Unsere Flexport Teams",
  },
  "View All Articles": {
    "zh-CN": "",
    "de-DE": "Zur Übersicht",
  },
  "For media inquires, please contact us at": {
    "zh-CN": "",
    "de-DE": "Für Presseanfragen kontaktieren Sie uns unter",
  },
  "For other inquiries, please contact us at": {
    "zh-CN": "",
    "de-DE": "Für Anfragen wenden Sie sich gerne an press@flexport.com.",
  },
  Download: {
    "zh-CN": "",
    "de-DE": "",
  },
  "In the News": {
    "zh-CN": "",
    "de-DE": "Aktuelle Nachrichten",
  },
  "Press Resources": {
    "zh-CN": "",
    "de-DE": "Pressematerialien",
  },
  "Find Out More": {
    "zh-CN": "更多信息",
    "de-DE": "Jetzt mehr erfahren",
  },
  "Upcoming Events": {
    "zh-CN": "最新活动",
    "de-DE": "",
  },
  "Enter your business email": {
    "zh-CN": "输入您的公司电子邮件地址",
    "de-DE": "",
  },
  "Sign Up For Flexport": {
    "zh-CN": "Flexport 帐号注册",
    "de-DE": "Anmeldung",
  },
  "Sign up for Flexport": {
    "zh-CN": "Flexport 帐号注册",
    "de-DE": "Kontaktformular",
  },
  "Sign Up With Flexport": {
    "zh-CN": "Flexport 帐号注册",
    "de-DE": "Kontaktformular",
  },
  "First Name": {
    "zh-CN": "名字",
    "de-DE": "Vorname",
  },
  "Last Name": {
    "zh-CN": "姓氏",
    "de-DE": "Nachname",
  },
  "Company Name": {
    "zh-CN": "公司名称",
    "de-DE": "Unternehmen",
  },
  "E-mail": {
    "zh-CN": "邮箱地址",
    "de-DE": "E-Mail-Adresse",
  },
  "I agree to the": {
    "zh-CN": "我同意",
    "de-DE": "Ich stimme den",
  },
  "Terms of Service": {
    "zh-CN": "服务条款",
    "de-DE": "Nutzungsbedingungen",
  },
  "Privacy Policy": {
    "zh-CN": "隐私政策",
    "de-DE": "Datenschutzerklärung zu",
  },
  "Privacy Policy Footer DE": {
    "zh-CN": "",
    "de-DE": "Datenschutzerklärung",
  },
  Imprint: {
    "zh-CN": "",
    "de-DE": "Impressum",
  },
  "Opt Out of Cookies": {
    "zh-CN": "",
    "de-DE": "",
  },
  and: {
    "zh-CN": "和",
    "de-DE": "und der",
  },
  "Business Phone": {
    "zh-CN": "电话号码",
    "de-DE": "Telefonnummer",
  },
  "Phone Number": {
    "zh-CN": "电话号码",
    "de-DE": "Telefonnummer",
  },
  "Work Email": {
    "zh-CN": "邮箱地址",
    "de-DE": "E-Mail-Adresse",
  },
  "Read Our Freight Market Updates": {
    "zh-CN": "阅读航运市场动态",
    "de-DE": "",
  },
  "Get weekly insights into all things freight, delivered right to your WeChat.":
    {
      "zh-CN": "关注微信公众号阅读每周的航运市场动态。",
      "de-DE":
        "Erhalten Sie wöchentliche Einblicke und Informationen rund um das Thema globaler Handel – direkt in Ihren Posteingang.",
    },
  "Scan the QR code to follow Flexport on WeChat now.": {
    "zh-CN": "立即扫描二维码关注 Flexport。",
    "de-DE": "",
  },
  "Track and Trace": {
    "zh-CN": "货物跟踪",
    "de-DE": "",
  },
  "Trade Services": {
    "zh-CN": "贸易服务",
    "de-DE": "Handelsservices",
  },
  "Trade Advisory": {
    "zh-CN": "贸易咨询",
    "de-DE": "Handelsberatung",
  },
  "Financial Services": {
    "zh-CN": "金融服务",
    "de-DE": "Handelsfinanzierung",
  },
  Insurance: {
    "zh-CN": "货物保险",
    "de-DE": "Transportversicherung",
  },
  APIs: {
    "zh-CN": "API 应用程序接口",
    "de-DE": "",
  },
  Glossary: {
    "zh-CN": "行业术语",
    "de-DE": "Flexport Glossar",
  },
  Media: {
    "zh-CN": "媒体报道",
    "de-DE": "",
  },
  Events: {
    "zh-CN": "最新活动",
    "de-DE": "",
  },
  "Featured Blog Posts": {
    "zh-CN": "最新博客文章",
    "de-DE": "Unsere aktuellsten Blogs",
  },
  "Ocean, Air, Ground, All From the Cloud": {
    "zh-CN": "在云端管理您的海、陆、空运",
    "de-DE": "",
  },
  "Expert Teams Dedicated to Your Business": {
    "zh-CN": "专注为您服务的专家团队",
    "de-DE": "",
  },
  LEGAL: {
    "zh-CN": "法律声明",
    "de-DE": "",
  },
  "Copyright © 2024 Flexport Inc.": {
    "zh-CN": "© 2024 Flexport, Inc. 版权所有",
    "de-DE": "",
  },
  "Customs brokerage services are provided by Flexport’s wholly-owned subsidiary, Flexport Customs LLC, a licensed customs brokerage with a national permit. International ocean freight forwarding services are provided by Flexport International LLC, a licensed Ocean Transportation Intermediary FMC# 025219NF. U.S. trucking services are provided by Flexport International, LLC, a FMCSA licensed property broker USDOT #2594279 and MC #906604-B. Cargo insurance is underwritten by an authorized insurance company and offered by Flexport affiliates. Insurance coverage is not available in all jurisdictions. See http://flx.to/insurance-notice for more cargo insurance information and disclosures. All transactions are subject to Flexport’s standard terms and conditions, available at www.flexport.com/terms":
    {
      "zh-CN":
        "美国清关服务由 Flexport 全资子公司且为美国持牌报关行的 Flexport Customs LLC 提供。国际海运代理服务由具有无船承运人资质 (FMC＃025219NF) 的 Flexport International LLC 提供。美国内陆拖车服务由具有 FMCSA 代理牌照 (USDOT #2594279 和 MC #906604-B) 的 Flexport International LLC 提供。所有交易均适用Flexport 之标准条款 (详情请访问 cn.flexport.com/terms)。",
      "de-DE": "",
    },
  "Terms of Use": {
    "zh-CN": "使用条款",
    "de-DE": "Nutzungsbedingungen",
  },
  "COVID-19 Trade Insights": {
    "zh-CN": "新型冠状病毒贸易见解",
    "de-DE": "COVID-19 Supply Chain Insights",
  },
  Updates: {
    "zh-CN": "最新消息",
    "de-DE": "Client Advisories",
  },
  "Thought Leadership": {
    "zh-CN": "思想领导",
    "de-DE": "Blog Posts",
  },
  News: {
    "zh-CN": "媒体报道",
    "de-DE": "Presse Update",
  },
  "By submitting this form, you agree that we may contact you using the information you provided in this form about our products and services. Your data will be used subject to Flexport’s Privacy Policy. You can unsubscribe at any time using the link in our emails.":
    {
      "zh-CN":
        "通过提交此表格，您同意我们使用您提供的数据与您联系，提供与您的请求/提交相关的 Flexport 飞协博产品和服务信息，包括教育资源和产品更新。我们将遵守 Flexport 飞协博的隐私政策使用您的数据。您可以在任何时候通过我们电子邮件底部提供的链接选择取消订阅。",
      "de-DE":
        "Mit dem Absenden dieses Formulars erklären Sie sich mit der Verwendung Ihrer Daten einverstanden, um Ihnen Informationen zu unseren Dienstleistungen zukommen zu lassen. Ihre Daten werden gemäß Flexports Datenschutzrichtlinien verarbeitet. Sie können sich jederzeit über den Abmeldelink in unseren E-Mails abmelden.",
    },
  "I would like to receive additional communications from Flexport, including educational sources, product updates, and outreach from representatives. I understand that I may opt-out at any time.":
    {
      "zh-CN":
        "我想从Flexport那里获得更多的资訊，包括教育资料，产品更新和销售推广。我了解我可以随时取消订阅",
      "de-DE":
        "Ja, ich möchte Neuigkeiten, E-Books, Einladungen zu Webinaren und Updates von Flexport erhalten. Flexport Mitarbeiter können mich gerne kontaktieren. Ich kann mich jederzeit ohne Angabe von Gründen abmelden.",
    },
  "Back to Blog": {
    "zh-CN": "",
    "de-DE": "Zur Übersicht",
  },
  "Featured Videos and Webinars": {
    "zh-CN": "",
    "de-DE": "Empfohlene Videos und Webinare",
  },
  "Sign Up for Freight Market Updates": {
    "zh-CN": "",
    "de-DE": "Jetzt einfach registrieren und Freight Market Updates erhalten",
  },
  "I agree to the storing and processing of my personal data by Flexport as described in the Terms of Service and Privacy Policy.":
    {
      "zh-CN": "",
      "de-DE":
        "Ich stimme der Speicherung und Verarbeitung meiner persönlichen Daten durch Flexport, wie in den Allgemeinen Geschäftsbedingungen und der Datenschutzerklärung beschrieben, zu.",
    },
  "Get weekly insights into all things freight, delivered right to your inbox.":
    {
      "zh-CN": "",
      "de-DE":
        "Erhalten Sie wöchentliche Einblicke und Informationen rund um das Thema globaler Handel – direkt in Ihren Posteingang.",
    },
  "Thanks for Subscribing!": {
    "zh-CN": "",
    "de-DE": "Vielen Dank für die Anmeldungen zu unserem Newsletter”",
  },
  "You are now subscribed to Freight Market Updates.": {
    "zh-CN": "",
    "de-DE": "Sie erhalten ab jetzt unsere Freight Market Updates",
  },
  "Enter Your Work Email": {
    "zh-CN": "输入您的公司电子邮件地址",
    "de-DE": "Bitte geben Sie hier Ihre E-Mail-Adresse ein",
  },
  "Invalid email": {
    "zh-CN": "",
    "de-DE": "Ungültige E-Mail-Adresse",
  },
  "Sign up here for original pieces on global trade and logistics.": {
    "zh-CN": "",
    "de-DE": "",
  },
  "You are now subscribed to the Flexport Blog.": {
    "zh-CN": "",
    "de-DE": "Sie erhalten ab jetzt unsere aktuellen Flexport Blogs",
  },
  "Flexport makes shipping your cargo transparent, reliable, and affordable": {
    "zh-CN": "",
    "de-DE":
      "Setzen Sie auf digitale Logistikprozesse und registrieren Sie sich hier.",
  },
  "Dangerous Goods": {
    "zh-CN": "危险品",
    "de-DE": "Gefahrgut",
  },
  Continue: {
    "zh-CN": "继续",
    "de-DE": "Weiter",
  },
  Finish: {
    "zh-CN": "完成",
    "de-DE": "Bestätigen",
  },
  "Thank You!": {
    "zh-CN": "感谢您！",
    "de-DE": "Vielen Dank für Ihre Anfrage.",
  },
  Required: {
    "zh-CN": "必填",
    "de-DE": "Pflichtfeld",
  },
  "Please enter an email address": {
    "zh-CN": "请输入邮箱地址",
    "de-DE": "Bitte hinterlegen Sie hier Ihre Mailadresse",
  },
  "Please enter a valid email address": {
    "zh-CN": "请输入有效的邮箱地址",
    "de-DE": "Bitte hinterlegen Sie hier eine gültige Mailadresse",
  },
  "Please enter a valid phone number": {
    "zh-CN": "请输入有效的电话号码",
    "de-DE": "Bitte hinterlegen Sie hier eine gültige Telefonnummer",
  },
  "Please enter a valid response": {
    "zh-CN": "Please enter a valid response",
    "de-DE": "Please enter a valid response",
  },
  Country: {
    "zh-CN": "国家",
    "de-DE": "Land",
  },
  "Select an option": {
    "zh-CN": "请选择",
    "de-DE": "Bitte wählen Sie eine der Optionen aus",
  },
  "An expert will get back to you very soon.": {
    "zh-CN": "我们会尽快与您联系。",
    "de-DE": "Wir melden uns kurzfristig bei Ihnen",
  },
  "Back to Flexport.com": {
    "zh-CN": "回到 Flexport.com",
    "de-DE": "Zurück zu Flexport.com",
  },
  "Back to flexport.com": {
    "zh-CN": "回到 Flexport.com",
    "de-DE": "Zurück zu Flexport.com",
  },
  "Less than 25": {
    "zh-CN": "低于 25",
    "de-DE": "Weniger als 25",
  },
  "None - I'm a first time shipper": {
    "zh-CN": "我首次使用物流服务",
    "de-DE": "Keins - Ich verschiffe zum ersten Mal",
  },
  "None - I don't ship freight": {
    "zh-CN": "不适用，我没有物流需求",
    "de-DE": "Keins - Ich versende keine Fracht",
  },
  "I'm a logistics provider": {
    "zh-CN": "不适用，我是物流服务商",
    "de-DE": "Ich biete Logistikdienstleistungen an",
  },
  "International Commercial Terms": {
    "zh-CN": "您最常用的贸易条款",
  },
  "EXW or FOB": {
    "zh-CN": "EXW 或 FOB",
  },
  "CIF, DAP, DDU or DDP": {
    "zh-CN": "CIF、DAP、DDU 或 DDP",
  },
  Other: {
    "zh-CN": "其他",
  },
  China: {
    "zh-CN": "中国大陆",
  },
  Macao: {
    "zh-CN": "中国澳门",
  },
  Taiwan: {
    "zh-CN": "中国台湾",
  },
  "Annual International Freight Shipments": {
    "zh-CN": "年度全球货运量",
    "de-DE": "Jährliches Sendungsvolumen",
  },
  "How We Hire": {
    "zh-CN": "我们如何招聘",
    "de-DE": "Ihr Weg zu Flexport",
  },
  "More About Flexport": {
    "zh-CN": "",
    "de-DE": "Mehr über Flexport",
  },
  "Follow Us on LinkedIn": {
    "zh-CN": "",
    "de-DE": "Folgen Sie uns auf LinkedIn",
  },
  "View Us on Glassdoor": {
    "zh-CN": "",
    "de-DE": "Besuchen Sie uns auf Glassdoor",
  },
  "View All Open Positions": {
    "zh-CN": "",
    "de-DE": "",
  },
  "About this author": {
    "zh-CN": "",
    "de-DE": "Über den Autor",
  },
  "Get Free Weekly Supply Chain News Updates": {
    "zh-CN": "",
    "de-DE": "Jetzt einfach registrieren und Freight Market Updates erhalten",
  },
  "Subscribe for the latest news on trade lanes, customs and tariff changes, and expert economic insight.":
    {
      "zh-CN": "",
      "de-DE":
        "Erhalten Sie wöchentliche Einblicke und Informationen rund um das Thema globaler Handel – direkt in Ihren Posteingang.",
    },
  Developers: {
    "zh-CN": "开发工程师",
    "de-DE": "Entwickler",
  },
  "Job Title": {
    "zh-CN": "职称",
    "de-DE": "Jobbezeichnung",
  },
  "Contact Us": {
    "zh-CN": "联系我们",
    "de-DE": "Kontaktieren Sie uns",
  },
  "Send us a message": {
    "zh-CN": "给我们发送信息",
    "de-DE": "Senden Sie uns eine Nachricht",
  },
  Email: {
    "zh-CN": "电子邮件",
    "de-DE": "Email",
  },
  Phone: {
    "zh-CN": "联络电话",
    "de-DE": "Telefon",
  },
  Message: {
    "zh-CN": "信息",
    "de-DE": "Nachricht",
  },
  General: {
    "zh-CN": "总部",
    "de-DE": "Impressum",
  },
  Online: {
    "zh-CN": "电邮",
    "de-DE": "Online",
  },
  "Global Offices": {
    "zh-CN": "全球办事处",
    "de-DE": "Globale Niederlassungen",
  },
  Send: {
    "zh-CN": "提交",
    "de-DE": "Absenden",
  },
  "Sorry, but we are unable to process your message": {
    "zh-CN": "很抱歉，我们此时无法处理您的信息",
    "de-DE":
      "Bitte entschuldigen Sie, aber leider können wir Ihre Nachricht nicht weiterleiten.",
  },
  "Thank you for your message. We will get back to you as soon as we can.": {
    "zh-CN": "感谢您的留言，我们会尽快与您联系。",
    "de-DE":
      "Vielen Dank für Ihre Nachricht! Wir werden uns so schnell wie möglich bei Ihnen melden.",
  },
  step: {
    "zh-CN": "step",
    "de-DE": "step",
  },
  "Form progress status": {
    "zh-CN": "Form progress status",
    "de-DE": "Form progress status",
  },
  completed: {
    "zh-CN": "completed",
    "de-DE": "completed",
  },
  "Country/Region": {
    "zh-CN": "国家/地区",
    "de-DE": "Land/Region",
  },
  "Country code": {
    "zh-CN": "国家地区代码",
    "de-DE": "Ländervorwahl",
  },
  "Select country": {
    "zh-CN": "Select country",
    "de-DE": "Select country",
  },
  "Something went wrong. Try again.": {
    "zh-CN": "Something went wrong. Try again.",
    "de-DE": "Something went wrong. Try again.",
  },
  "That's taken care of.": {
    "zh-CN": "That's taken care of.",
    "de-DE": "That's taken care of.",
  },
  "Enter your email": {
    "zh-CN": "Enter your email",
    "de-DE": "Enter your email",
  },
  Search: {
    "zh-CN": "Search",
    "de-DE": "Search",
  },
  "Hello! What can we help you find?": {
    "zh-CN": "Hello! What can we help you find?",
    "de-DE": "Hello! What can we help you find?",
  },
  "Visit us on": {
    "zh-CN": "Visit us on",
    "de-DE": "Visit us on",
  },
  "Skip to content": {
    "zh-CN": "Skip to content",
    "de-DE": "Skip to content",
  },
  "Share Page": {
    "zh-CN": "",
    "de-DE": "",
  },
  "Share Page via Email": {
    "zh-CN": "",
    "de-DE": "",
  },
  "Share Page via Twitter": {
    "zh-CN": "",
    "de-DE": "",
  },
  "Share Page via LinkedIn": {
    "zh-CN": "",
    "de-DE": "",
  },
  "Share Page via Facebook": {
    "zh-CN": "",
    "de-DE": "",
  },
  "Copy Page Link": {
    "zh-CN": "",
    "de-DE": "",
  },
  Breadcrumbs: {
    "zh-CN": "",
    "de-DE": "",
  },
  "close drawer menu": {
    "zh-CN": "close drawer menu",
    "de-DE": "close drawer menu",
  },
  "Ready to Apply?": {
    "zh-CN": "准备好加入我们了吗",
    "de-DE": "Bewirb dich jetzt",
  },
  "Select a team": {
    "zh-CN": "选择团队",
    "de-DE": "Team auswählen",
  },
  "Select a location": {
    "zh-CN": "选择地点",
    "de-DE": "Standort auswählen",
  },
  "See Open Roles": {
    "zh-CN": "查看空缺职位",
    "de-DE": "Offene Stellen ansehen",
  },
  "Clear Filters": {
    "zh-CN": "取消筛选",
    "de-DE": "Filter zurücksetzen",
  },
  "Your Next Role Awaits": {
    "zh-CN": "机会正等着您呢",
    "de-DE": "Finde jetzt deine nächste Stelle",
  },
  "View Openings": {
    "zh-CN": "查看空缺职位",
    "de-DE": "Offene Stellen ansehen",
  },
  "Account Solutions & Implementation": {
    "zh-CN": "客户解决方案与实施",
    "de-DE": "",
  },
  "Business Enablement": {
    "zh-CN": "业务支持",
    "de-DE": "",
  },
  "Business Operations": {
    "zh-CN": "业务运营",
    "de-DE": "",
  },
  "Client Solutions": {
    "zh-CN": "客户解决方案",
    "de-DE": "",
  },
  Customs: {
    "zh-CN": "海关",
    "de-DE": "",
  },
  "Data Science & Analytics": {
    "zh-CN": "数据科学与分析",
    "de-DE": "",
  },
  Finance: {
    "zh-CN": "财务",
    "de-DE": "",
  },
  "Flexport Ecosystem ": {
    "zh-CN": "Flexport 飞协博生态系统",
    "de-DE": "",
  },
  "Flexport.org": {
    "zh-CN": "",
    "de-DE": "",
  },
  "General Management": {
    "zh-CN": "管理",
    "de-DE": "",
  },
  "Global Brand": {
    "zh-CN": "全球品牌",
    "de-DE": "",
  },
  "IT, Security & Infrastructure": {
    "zh-CN": "IT、安全与基础设施",
    "de-DE": "",
  },
  Legal: {
    "zh-CN": "法务",
    "de-DE": "",
  },
  Marketing: {
    "zh-CN": "营销",
    "de-DE": "",
  },
  "Office of CEO": {
    "zh-CN": "首席执行官办公室",
    "de-DE": "",
  },
  "Office of CTO": {
    "zh-CN": "首席技术官办公室",
    "de-DE": "",
  },
  "Operational Excellence": {
    "zh-CN": "卓越运营",
    "de-DE": "",
  },
  Partnerships: {
    "zh-CN": "合作关系",
    "de-DE": "",
  },
  People: {
    "zh-CN": "人力资源",
    "de-DE": "",
  },
  "Product Management": {
    "zh-CN": "产品管理",
    "de-DE": "",
  },
  Sales: {
    "zh-CN": "销售",
    "de-DE": "",
  },
  "Supply Chain Operations": {
    "zh-CN": "供应链运营",
    "de-DE": "",
  },
  "Technical Program Management": {
    "zh-CN": "技术计划管理",
    "de-DE": "",
  },
  "Trade & Financial Services": {
    "zh-CN": "贸易和财务服务",
    "de-DE": "",
  },
  "Trade Lane Management, Fulfillment & Procurement": {
    "zh-CN": "贸易航线管理",
    "de-DE": "",
  },
  Trucking: {
    "zh-CN": "卡车运输",
    "de-DE": "",
  },
  "User Experience": {
    "zh-CN": "用户体验",
    "de-DE": "",
  },
  Warehousing: {
    "zh-CN": "仓储",
    "de-DE": "",
  },
  "Workplace & Facilities": {
    "zh-CN": "办公室和设施行政",
    "de-DE": "",
  },
  Amsterdam: {
    "zh-CN": "阿姆斯特丹",
    "de-DE": "",
  },
  Atlanta: {
    "zh-CN": "亚特兰大",
    "de-DE": "",
  },
  Belgium: {
    "zh-CN": "比利时",
    "de-DE": "",
  },
  Bellevue: {
    "zh-CN": "贝尔维尤",
    "de-DE": "",
  },
  Chicago: {
    "zh-CN": "芝加哥",
    "de-DE": "",
  },
  Cologne: {
    "zh-CN": "科隆",
    "de-DE": "",
  },
  Copenhagen: {
    "zh-CN": "哥本哈根",
    "de-DE": "",
  },
  Dallas: {
    "zh-CN": "达拉斯",
    "de-DE": "",
  },
  Frankfurt: {
    "zh-CN": "法兰克福",
    "de-DE": "",
  },
  Hamburg: {
    "zh-CN": "汉堡",
    "de-DE": "",
  },
  "Ho Chi Minh City": {
    "zh-CN": "胡志明市",
    "de-DE": "",
  },
  "Hong Kong": {
    "zh-CN": "中国香港",
    "de-DE": "",
  },
  London: {
    "zh-CN": "伦敦",
    "de-DE": "",
  },
  "Los Angeles": {
    "zh-CN": "洛杉矶",
    "de-DE": "",
  },
  Miami: {
    "zh-CN": "迈阿密",
    "de-DE": "",
  },
  Munich: {
    "zh-CN": "慕尼黑",
    "de-DE": "",
  },
  "New York City": {
    "zh-CN": "纽约市",
    "de-DE": "",
  },
  Phoenix: {
    "zh-CN": "凤凰城",
    "de-DE": "",
  },
  Plano: {
    "zh-CN": "普拉诺",
    "de-DE": "",
  },
  Portland: {
    "zh-CN": "波特兰",
    "de-DE": "",
  },
  "San Francisco": {
    "zh-CN": "三藩市",
    "de-DE": "",
  },
  Seoul: {
    "zh-CN": "首尔",
    "de-DE": "",
  },
  Shanghai: {
    "zh-CN": "上海",
    "de-DE": "",
  },
  Shenzhen: {
    "zh-CN": "深圳",
    "de-DE": "",
  },
  Singapore: {
    "zh-CN": "新加坡",
    "de-DE": "",
  },
  Stuttgart: {
    "zh-CN": "斯图加特",
    "de-DE": "",
  },
  Sweden: {
    "zh-CN": "瑞典",
    "de-DE": "",
  },
  Taipei: {
    "zh-CN": "台北",
    "de-DE": "",
  },
  Toronto: {
    "zh-CN": "多伦多",
    "de-DE": "",
  },
  "U.S. Remote": {
    "zh-CN": "在美远程工作",
    "de-DE": "",
  },
  "Close search panel": {
    "zh-CN": "Close search panel",
    "de-DE": "Close search panel",
  },
  "Show search panel": {
    "zh-CN": "Show search panel",
    "de-DE": "Show search panel",
  },
  "Search panel showed": {
    "zh-CN": "Search panel showed",
    "de-DE": "Search panel showed",
  },
  "Search panel closed": {
    "zh-CN": "Search panel closed",
    "de-DE": "Search panel closed",
  },
  "Open blog menu": {
    "zh-CN": "Open blog menu",
    "de-DE": "Open blog menu",
  },
  "Close blog menu": {
    "zh-CN": "Close blog menu",
    "de-DE": "Close blog menu",
  },
  Overview: {
    "zh-CN": "概况",
    "de-DE": "",
  },
  Teams: {
    "zh-CN": "团队",
    "de-DE": "",
  },
  Locations: {
    "zh-CN": "地点",
    "de-DE": "",
  },
  "Diversity & Inclusion": {
    "zh-CN": "多样性和包容性",
    "de-DE": "",
  },
  "Find Your Role at Flexport": {
    "zh-CN": "在 Flexport 飞协博寻找机会",
    "de-DE": "",
  },
  Department: {
    "zh-CN": "团队",
    "de-DE": "",
  },
  Location: {
    "zh-CN": "地点",
    "de-DE": "",
  },
  Openings: {
    "zh-CN": "空缺职位",
    "de-DE": "",
  },
  "Play Video": {
    "zh-CN": "Play Video",
    "de-DE": "Play Video",
  },
  Day: {
    "zh-CN": "Day",
    "de-DE": "Day",
  },
  Month: {
    "zh-CN": "Month",
    "de-DE": "Month",
  },
  Year: {
    "zh-CN": "Year",
    "de-DE": "Year",
  },
  "No results found": {
    "zh-CN": "No results found",
    "de-DE": "No results found",
  },
  Origin: {
    "zh-CN": "Origin",
    "de-DE": "Origin",
  },
  Destination: {
    "zh-CN": "Destination",
    "de-DE": "Destination",
  },
  Cargo: {
    "zh-CN": "Cargo",
    "de-DE": "Cargo",
  },
  "Freight Quote": {
    "zh-CN": "Freight Quote",
    "de-DE": "Freight Quote",
  },
  "Where are you shipping from?": {
    "zh-CN": "Where are you shipping from?",
    "de-DE": "Where are you shipping from?",
  },
  "Where are you shipping to?": {
    "zh-CN": "Where are you shipping to?",
    "de-DE": "Where are you shipping to?",
  },
  "Ocean - Full container load (FCL)": {
    "zh-CN": "Ocean - Full container load (FCL)",
    "de-DE": "Ocean - Full container load (FCL)",
  },
  "Ocean - Less container load (LCL)": {
    "zh-CN": "Ocean - Less container load (LCL)",
    "de-DE": "Ocean - Less container load (LCL)",
  },
  Air: {
    "zh-CN": "Air",
    "de-DE": "Air",
  },
  "20' Standard": {
    "zh-CN": "20' Standard",
    "de-DE": "20' Standard",
  },
  "40' Standard": {
    "zh-CN": "40' Standard",
    "de-DE": "40' Standard",
  },
  "40' High Cube": {
    "zh-CN": "40' High Cube",
    "de-DE": "40' High Cube",
  },
  "45' High Cube": {
    "zh-CN": "45' High Cube",
    "de-DE": "45' High Cube",
  },
  Batteries: {
    "zh-CN": "Batteries",
    "de-DE": "Batteries",
  },
  "Hazardous Materials": {
    "zh-CN": "Hazardous Materials",
    "de-DE": "Hazardous Materials",
  },
  "Creams, liquids, powders": {
    "zh-CN": "Creams, liquids, powders",
    "de-DE": "Creams, liquids, powders",
  },
  Magnets: {
    "zh-CN": "Magnets",
    "de-DE": "Magnets",
  },
  "Weight (in KG)": {
    "zh-CN": "Weight (in KG)",
    "de-DE": "Weight (in KG)",
  },
  "Volume (in CBM)": {
    "zh-CN": "Volume (in CBM)",
    "de-DE": "Volume (in CBM)",
  },
  "Estimated cargo value (in USD)": {
    "zh-CN": "Estimated cargo value (in USD)",
    "de-DE": "Estimated cargo value (in USD)",
  },
  "Do any of your products contain the following goods?": {
    "zh-CN": "Do any of your products contain the following goods?",
    "de-DE": "Do any of your products contain the following goods?",
  },
  Save: {
    "zh-CN": "Save",
    "de-DE": "Save",
  },
  Update: {
    "zh-CN": "Update",
    "de-DE": "Update",
  },
  Cancel: {
    "zh-CN": "Cancel",
    "de-DE": "Cancel",
  },
  Edit: {
    "zh-CN": "Edit",
    "de-DE": "Edit",
  },
  "Shipment Information": {
    "zh-CN": "Shipment Information",
    "de-DE": "Shipment Information",
  },
  "Cargo Ready Date": {
    "zh-CN": "Cargo Ready Date",
    "de-DE": "Cargo Ready Date",
  },
  "Est. Transit Time": {
    "zh-CN": "Est. Transit Time",
    "de-DE": "Est. Transit Time",
  },
  "Port to Port": {
    "zh-CN": "Port to Port",
    "de-DE": "Port to Port",
  },
  "Port to Door": {
    "zh-CN": "Port to Door",
    "de-DE": "Port to Door",
  },
  Submit: {
    "de-DE": "Absenden",
  },
  Subscribe: {
    "de-DE": "Abonnieren",
  },
  "This site is protected by reCAPTCHA Enterprise and the Google": {
    "zh-CN": "本网站已获 reCAPTCHA 和 Google 的隐私政策和服务条款保护。",
    "de-DE":
      "Diese Webseite wird durch das Google reCAPTCHA geschützt. Es gelten die ",
  },
  "as well as the": {
    "zh-CN": "",
    "de-DE": "sowie die",
  },
  "recaptcha-privacy-policy-de": {
    "zh-CN": "",
    "de-DE": "Datenschutzbestimmungen",
  },
  "recaptcha-term-by-google": {
    "zh-CN": "",
    "de-DE": "von Google.",
  },
  "Shanghai ICP No. 16041494-1": {
    "zh-CN": "沪ICP备16041494号-1",
    "de-DE": "Shanghai ICP No. 16041494-1",
  },
};
