/*
  This react context is from main repo
  See: https://github.flexport.io/flexport/flexport/blob/master/webpack/assets/javascripts/context/WindowSizeContext.jsx
*/

import React, {type ReactNode, type ReactElement} from "react";
import invariant from "../utils/invariant";

export const WindowSize: React.Context<State> = React.createContext({
  height: 0,
  width: 0,
});

interface WindowSizeProviderProps {
  children: ReactNode;
}

interface State {
  width: number;
  height: number;
}

/**
 * In server-side rendering we don't have access to window size
 * So we do not attach a provider to the component hierarchy.
 * @param {WindowSizeProviderProps} props
 * @return {ReactElement}
 */
function SSRWindowSizeProvider(props: WindowSizeProviderProps): ReactElement {
  const {children} = props;
  return <>{children}</>;
}

/**
 * window size provider with resize event listener
 * @param {WindowSizeProviderProps} props
 * @return {ReactElement}
 */
function WindowSizeProvider(props: WindowSizeProviderProps): ReactElement {
  const {children} = props;
  const {body} = document;
  invariant(body);
  const [height, setHeight] = React.useState(body.clientHeight);
  const [width, setWidth] = React.useState(body.clientWidth);
  const handleResize = () => {
    setHeight(body.clientHeight);
    setWidth(body.clientWidth);
  };
  React.useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  });

  const value = {height, width};
  return <WindowSize.Provider value={value}>{children}</WindowSize.Provider>;
}

const Provider =
  typeof window === "undefined" || typeof document === "undefined"
    ? SSRWindowSizeProvider
    : WindowSizeProvider;

export default Provider;
