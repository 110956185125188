/**
 * Use invariant() to assert state which your program assumes to be true. This
 * is a customized version of Facebook's invariant assertion helper[1].
 *
 * In new code, use invariant() to ensure logic is the same in prod and dev.
 *
 * TODO(dmnd): Once this is used in a lot of places implemented error codes so
 * that the messages are stripped from productions builds.
 *
 * [1]: https://github.com/facebook/fbjs/blob/master/packages/fbjs/src/__forks__/invariant.js
 */

/* This function is copied directly out of Core. */

import {NODE_ENV} from "../../config";

/**
 *
 * @param {(str: string) => void} prodFn
 * @param {boolean | HTMLElement} condition
 * @param {string} format
 * @param {Array<string>} args
 *
 * @return {Error | void}
 */
function _invariant(
  prodFn: (str: string) => void,
  condition: boolean | HTMLElement,
  format?: string,
  ...args: Array<string>
): Error | void {
  if (!condition) {
    // We use sprintf instead of template strings because we only want to do the
    // (sometimes nontrivial) work of building the final message when the
    // invariant is violated.
    let i = 0;
    const message = format
      ? // eslint-disable-next-line no-plusplus,indent
        format.replace(/%s/g, () => args[i++])
      : "Invariant violated without message.";
    if (NODE_ENV !== "production") {
      throwMessage(message);
    } else {
      prodFn(message);
    }
  }
}

/**
 * The throw message invariant handler will cause an error to be thrown.
 *
 * In a React lifecycle event, this error will get caught by the next available error boundary.
 *
 * In a non-React code execution path, this will propogate all the way to the global Sentry
 * listener, and get captured at that level.
 *
 * @param {string} error
 *
 * @return {Error}
 */
function throwMessage(message: string): Error {
  throw new Error(message);
}

/**
 * Use invariant() to assert state which your program assumes to be true.
 *
 * If the condition evaluates to false, this will throw an exception in both
 * dev and prod.
 *
 * Provide sprintf-style format (only %s is supported) and arguments to provide
 * information about what broke and what you were expecting.
 */
const invariant = _invariant.bind(null, throwMessage);
export default invariant;
