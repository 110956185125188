import React, {type ReactNode} from "react";
import {SentryType, logErrorToSentry} from "/src/utils/sentry";

interface ErrorInfo {
  componentStack: string;
}

/**
 * wrapper helper method to send error log to sentry
 * @param {Error} error
 * @param {ErrorInfo} errorInfo
 */
const sendErrorToSentry = (error: Error, errorInfo: ErrorInfo): void => {
  const {componentStack} = errorInfo;
  const errorContext: SentryType = {
    extras: {
      componentStack,
    },
  };
  logErrorToSentry(error, errorContext);
};

interface ErrorBoundaryProps {
  children: ReactNode;
  fallbackComponent?: ReactNode;
  onError?: (error: Error, componentStack: string) => void;
  source?: string;
}

interface State {
  error: Error | null | undefined;
}

interface ErrorToSilent {
  wistia: boolean;
  swiper: boolean;
}

class ErrorBoundary extends React.Component<ErrorBoundaryProps, State> {
  state = {
    error: null,
  };

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    /*
      These are types of errors, typically from third parties that have been loud and not been representing anything that is broken,
      we still want them to be logged in case something is really broken,
      but want to group these all so they can be easily silenced on Sentry
    */
    const sourcesWithErrorsToSilence: ErrorToSilent = {
      wistia: true,
      swiper: true,
    };

    this.setState({error});
    const {source} = this.props;
    if (source && sourcesWithErrorsToSilence[source as keyof ErrorToSilent]) {
      const sources = Object.keys(sourcesWithErrorsToSilence).join("/ ");
      const parsedError = error.message || error.stack || error.toString();
      const errorToThrow = new Error(
        `Silenceable error from ${sources}, cause: ${parsedError}`
      );
      sendErrorToSentry(errorToThrow, errorInfo);
    } else sendErrorToSentry(error, errorInfo);
    const {onError} = this.props;
    const {componentStack} = errorInfo;
    if (onError) {
      onError(error, componentStack);
    }
  }

  render() {
    const {error} = this.state;
    const {children, fallbackComponent} = this.props;
    if (!error) {
      return children;
    }

    return fallbackComponent || children;
  }
}

export default ErrorBoundary;
