import React from "react";

export interface GDPRContextState {
  isEU: boolean;
  isEMEA: boolean;
  countryCode: string | undefined | null;
}

const GDPRContext = React.createContext<GDPRContextState>({
  isEU: false,
  isEMEA: false,
  countryCode: null,
});

export default GDPRContext;
