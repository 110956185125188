const EMEA_COUNTRIES: {[key: string]: string} = {
  // Middle East
  AF: "AF",
  AM: "AM",
  AZ: "AZ",
  BH: "BH",
  CY: "CY",
  GE: "GE",
  IR: "IR",
  IQ: "IQ",
  IL: "IL",
  JO: "JO",
  KZ: "KZ",
  KW: "KW",
  LB: "LB",
  OM: "OM",
  PS: "PS",
  QA: "QA",
  SA: "SA",
  SY: "SY",
  TR: "TR",
  AE: "AE",
  YE: "YE",

  // Africa
  DZ: "DZ",
  AO: "AO",
  BJ: "BJ",
  BW: "BW",
  BF: "BF",
  BI: "BI",
  CM: "CM",
  CV: "CV",
  CF: "CF",
  TD: "TD",
  KM: "KM",
  CD: "CD",
  CG: "CG",
  CI: "CI",
  DJ: "DJ",
  EG: "EG",
  GQ: "GQ",
  ER: "ER",
  ET: "ET",
  GA: "GA",
  GM: "GM",
  GH: "GH",
  GN: "GN",
  GW: "GW",
  KE: "KE",
  LS: "LS",
  LR: "LR",
  LY: "LY",
  MG: "MG",
  MW: "MW",
  ML: "ML",
  MR: "MR",
  MU: "MU",
  YT: "YT",
  MA: "MA",
  MZ: "MZ",
  NA: "NA",
  NE: "NE",
  NG: "NG",
  RW: "RW",
  SH: "SH",
  ST: "ST",
  SN: "SN",
  SC: "SC",
  SL: "SL",
  SO: "SO",
  ZA: "ZA",
  GS: "GS",
  SS: "SS",
  SD: "SD",
  SZ: "SZ",
  TZ: "TZ",
  TG: "TG",
  TN: "TN",
  UG: "UG",
  EH: "EH",
  ZM: "ZM",
  ZW: "ZW",

  // Europe
  AX: "AX",
  AL: "AL",
  AD: "AD",
  AT: "AT",
  BY: "BY",
  BE: "BE",
  BA: "BA",
  BG: "BG",
  HR: "HR",
  CZ: "CZ",
  DK: "DK",
  EE: "EE",
  FI: "FI",
  FR: "FR",
  DE: "DE",
  GI: "GI",
  GR: "GR",
  GL: "GL",
  GG: "GG",
  VA: "VA",
  HU: "HU",
  IS: "IS",
  IE: "IE",
  IM: "IM",
  IT: "IT",
  JE: "JE",
  LI: "LI",
  LV: "LV",
  LT: "LT",
  LU: "LU",
  MK: "MK",
  MT: "MT",
  MD: "MD",
  MC: "MC",
  ME: "ME",
  NL: "NL",
  NO: "NO",
  PL: "PL",
  PT: "PT",
  RE: "RE",
  RO: "RO",
  RU: "RU",
  SM: "SM",
  RS: "RS",
  SK: "SK",
  SI: "SI",
  SJ: "SJ",
  ES: "ES",
  SE: "SE",
  CH: "CH",
  UA: "UA",
  GB: "GB",
};

export function isEMEACountryCode(countryCode: string): boolean {
  return !!EMEA_COUNTRIES[countryCode];
}

export function containsEMEA(regions: string[] = []): boolean {
  return regions?.includes("EMEA");
}
