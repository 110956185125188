import React, {
  type ReactElement,
  type ReactNode,
  useEffect,
  useMemo,
  useState,
} from "react";
import GDPRContext from "/src/contexts/GDPRContext";
import TrackingTags from "../../tracking-tags/TrackingTags";
import {IS_PROD} from "/src/config";
import {isEUCountryCode} from "../utils/GDPRWrapperUtils";
import {isEMEACountryCode} from "/src/utils/geolocate/geolocateUtils";

interface GDPRWrapperProps {
  children?: ReactNode;
}

interface KetchGeoIP {
  countryCode: string;
  region: string;
}

const GDPRWrapper = (props: GDPRWrapperProps): ReactElement => {
  const {children} = props;

  const [countryCode, setCountryCode] = useState<string | null>(null);
  const [isEU, setIsEU] = useState(false);
  const [isEMEA, setIsEMEA] = useState(false);

  useEffect(() => {
    function geolocationPlugin(host: any) {
      // Receive only initial configuration get
      host.once("geoip", (geoip: KetchGeoIP) => {
        const {countryCode} = geoip;
        setCountryCode(countryCode);
        setIsEU(isEUCountryCode(countryCode));
        setIsEMEA(isEMEACountryCode(countryCode));
      });
    }

    // @ts-ignore
    window.ketch("registerPlugin", geolocationPlugin);
  }, [countryCode]);

  const GDPRState = useMemo(
    () => ({
      isEU,
      isEMEA,
      countryCode,
    }),
    [isEU, isEMEA, countryCode]
  );

  return (
    <GDPRContext.Provider value={GDPRState}>
      {IS_PROD && <TrackingTags />}
      {children}
    </GDPRContext.Provider>
  );
};

export default GDPRWrapper;
